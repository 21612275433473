import React, { Component } from "react";
// import axios from "axios"
import { navigateTo } from "gatsby-link";
import { Row, Col, Card } from "react-bootstrap";
import Recaptcha from "react-google-recaptcha";
import SEO from "../components/seo";
import "../styles/style.css";
import Layout from "../components/layout";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGavel,
  faEnvelope,
  faUniversalAccess,
  faExclamationTriangle,
  faCode,
  faShieldAlt,
  faHandshake,
  faQuoteLeft,
  faQuoteRight,
  faPhotoVideo,
} from "@fortawesome/free-solid-svg-icons";
import { CardHeader } from "react-bootstrap/Card";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

const RECAPTCHA_KEY = process.env.GATSBY_SITE_RECAPTCHA_KEY;

class BetterAccess extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    setTimeout(() => {
      console.log("hide gcaptcha field!");
      if (document.getElementById("g-recaptcha-response")) {
        document
          .getElementById("g-recaptcha-response")
          .setAttribute("aria-hidden", "true");
        document
          .getElementById("g-recaptcha-response")
          .setAttribute("aria-label", "hidden");
      }
    }, 2000);
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    console.log("form changed", e.target.name, e.target.value);
  };

  handleRecaptcha = (value) => {
    this.setState({ "g-recaptcha-response": value });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    // put error text inside of the alert element for readers

    let errorList = [];

    let fields = ["name", "email", "message"];

    for (let field in fields) {
      let fieldName = fields[field];
      let currentValue = this.state[fieldName];
      let checkRequired = currentValue == "" || currentValue == undefined;
      console.log(fieldName, currentValue);

      if (fieldName == "email") {
        let emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

        if (currentValue && !currentValue.match(emailPattern)) {
          errorList.push("Please enter a valid email address");
        }
      }

      if (checkRequired) {
        errorList.push("The " + fieldName + " field is required");
      }
    }

    if (errorList.length > 0) {
      this.setState({
        errorListRole: "alert",
        errorList: errorList,
      });

      return false;
    }

    const form = e.target;
    const data = encode({
      "form-name": form.getAttribute("name"),
      ...this.state,
    });
    fetch(form.getAttribute("action"), {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: data,
    })
      .then(() => navigateTo(form.getAttribute("action")))
      .catch((error) => alert(error));
  };

  render() {
    let infoCards = [
      {
        header: "The law",
        icon: faGavel,
        text:
          "ADA Title III requires that persons with disabilities be able to use any publicly available product or service. It was enacted in 1991 before the web took off, but recently the DOJ has suggested that private businesses follow the Web Content Accessibility Guidelines (WCAG) 2.0. Unfortunately, most websites are not compliant.",
      },
      {
        header: "Why care?",
        icon: faExclamationTriangle,
        text:
          "Everyone should be able to use your website. Also, if your website is not compliant, you are vulnerable to receiving a legal complaint at any time. Attorneys are targeting small businesses and we have seen settlements as high as $50,000, which does not even include the cost to fix the website to prevent another lawsuit.",
      },
      {
        header: "What we do",
        icon: faShieldAlt,
        text:
          "Better Access helps you understand whether your website follows the law. We offer Audit and Remediation services that determine if you are compliant and fix the identified issues for you. Our team follows the DOJ’s recommended WCAG 2.0. Let's get you compliant before you get sued and make the web accessible to all.",
      },
      {
        header: "Audit",
        icon: faPhotoVideo,
        text:
          "Every project starts with a thorough audit that includes a close look at every element on every page of your website. We manually check deep in the code to make sure all images, videos, forms, and other content are in line with the law. As part of the audit process, we provide a detailed report that identifies which parts of the law you are not compliant with.",
      },
      {
        header: "Remediate",
        icon: faCode,
        text:
          "Based on what we discover, we will propose a plan to fix any website components that are out of compliance. We can make all of the necessary code changes for you. After the audit, many basic websites can be updated within two weeks. For more robust sites, we may need a phased approach. We also provide accessibility training if you have an existing development team.",
      },
      {
        header: "Why us?",
        icon: faHandshake,
        text:
          "We are a tight-knit team of engineers and business professionals with a wide range of experience, including developing WCAG compliant WordPress Themes, setting up and maintaining websites for small businesses, and overseeing large scale HIPAA and GDPR compliance. We will work hard to make your website compliant so everyone can use your site, and you don’t have to worry about a lawsuit.",
      },
    ];

    let infoCardsHTML = [];

    for (let i in infoCards) {
      infoCardsHTML.push(
        <Col key={i} md={true}>
          <div className="card spaced">
            <div className="card-header">
              <h3 className="card-header-text">
                <FontAwesomeIcon icon={infoCards[i].icon}></FontAwesomeIcon>
                {infoCards[i].header}
              </h3>
            </div>
            <div className="card-body">{infoCards[i].text}</div>
          </div>
        </Col>
      );
      if ((i + 1) % 3 === 0) {
        infoCardsHTML.push(<div key={i + "spacer"} className="w-100"></div>);
      }
    }

    let testimonials = [
      {
        text:
          "I was anxious after my friend, who has a gallery website, got served a complaint out of the blue. He ended up paying a large settlement, and I was worried that my company's site might be at risk. Better Access fixed my website on short notice and improved the design at a price that small businesses can afford.",
        from: "Ellen Volpe",
        organization: "Executive Director of ABA of NY",
      },
      {
        text:
          "As the owner of an educational website, I was troubled to learn that my website was in violation of the Americans with Disabilities Act (ADA). After trying to remedy the problem myself, I soon realized that WIX being WIX couldn’t allow me to customize my site to make it accessible. Better Access provided a speedy audit, made the site fully ADA compliant (and legally worry-free), faster on mobile, and cheaper to host. It was a pleasure to work with them.",
        from: "Jeffrey Peyton",
        organization: "Owner of PlayTectonics",
      },
    ];

    let testimonialsHTML = [];

    for (let i in testimonials) {
      testimonialsHTML.push(
        <Col key={i} md={true} className="quote">
          <p>
            <span className="quote-icon">
              <FontAwesomeIcon icon={faQuoteLeft}></FontAwesomeIcon>{" "}
            </span>
            <i> {testimonials[i].text} </i>
          </p>
          <span>
            {testimonials[i].from}
            <br />
            {testimonials[i].organization}
          </span>
        </Col>
      );
    }

    return (
      <>
        <SEO
          title="Better Access"
          description="Professional web accessibility services"
        />
        <Layout>
          <section className="splash">
            <div className="container-fluid">
              <h2>Web Accessibility</h2>
              <h3>Help every user get what they need online.</h3>
            </div>
          </section>
          <section className="container-fluid">
            <Row className="info-cards">{infoCardsHTML}</Row>
          </section>
          <section className="testimonials bg-light">
            <Row className="justify-content-center">
              <h3>What our clients say</h3>
            </Row>
            <Row>
              <Col md={true} className="quote">
                <p>
                  <span className="quote-icon">
                    <FontAwesomeIcon icon={faQuoteLeft}></FontAwesomeIcon>
                  </span>
                  <i>
                    ‘Genuine expert’ is the phrase that comes to mind to
                    describe Zach. When we had an unexpectedly open role for our
                    Accessibility Lead with several projects in flight, Zach
                    jumped in immediately, enabling us to deliver projects on
                    time and continues to be a key contributor to our digital
                    success. He brings a rare mix of standards expertise, an
                    understanding of technology, empathy for our users, and the
                    ability to work through solutions with a team. As a team
                    member Zach earns my highest recommendation.
                  </i>
                </p>
                <span>
                  Sharon Denning
                  <br />
                  Director of UX, The New York Public Library
                </span>
              </Col>
            </Row>
            <Row>{testimonialsHTML}</Row>
          </section>
          <section className="splash contact">
            <div className="container-fluid">
              <Row className="justify-content-center">
                <Col md={8}>
                  <Card>
                    <div className="card-header">
                      <h3 className="card-header-text">
                        <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon>Got
                        a minute?
                      </h3>
                      <p>Let's chat about what we can do for you.</p>
                    </div>
                    <div className="card-body">
                      <form
                        name="contact"
                        method="POST"
                        data-netlify="true"
                        action="/thankyou"
                        data-netlify-recaptcha="true"
                        onSubmit={this.handleSubmit}
                      >
                        <label htmlFor="name-input">Name:</label>
                        <input
                          id="name-input"
                          name="name"
                          autoComplete="name"
                          placeholder="ex. John Smith"
                          onChange={this.handleChange}
                        />

                        <label htmlFor="email-input">Email:</label>
                        <input
                          id="email-input"
                          name="email"
                          autoComplete="email"
                          placeholder="ex. john@smith.com"
                          onChange={this.handleChange}
                        />

                        <label htmlFor="message-input">Message:</label>
                        <textarea
                          id="message-input"
                          name="message"
                          placeholder="ex. Hi, I'd like more information."
                          onChange={this.handleChange}
                        ></textarea>
                        <Recaptcha
                          ref="recaptcha"
                          sitekey={RECAPTCHA_KEY}
                          onChange={this.handleRecaptcha}
                        />
                        <button
                          type="submit"
                          aria-label="Send message"
                          className="btn btn-primary"
                        >
                          Send
                        </button>

                        <div role={this.state.errorListRole}>
                          {this.state.errorList
                            ? this.state.errorList.map((error) => (
                                <p className="form-error">*{error}</p>
                              ))
                            : ""}
                        </div>
                      </form>
                    </div>
                  </Card>
                </Col>
              </Row>
            </div>
          </section>
        </Layout>
      </>
    );
  }
}

export default BetterAccess;
